import styled from '@emotion/styled';

const Title = styled('h1')`
  font-size: 4rem;

  @media (min-width: 768px) {
    font-size: 6rem;
  }

  @media (min-width: 992px) {
    font-size: 6rem;
  }
`;

export default Title;
