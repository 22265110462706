import { Link } from 'gatsby';
import styled from '@emotion/styled';

const CallToAction = styled(Link)`
  background-color: #333;
  color: #fff;
  display: inline-block;
  padding: 1rem 1.5rem;
  transition: all 0.3s ease-out;
  text-decoration: none;

  &:hover {
    background-color: #000;
  }
`;

export default CallToAction;
