import React from 'react';
import styled from '@emotion/styled';

import CallToAction from '../call-to-action';

const Article = styled('article')`
  background-color: #fff;
  border: 1px solid #ececec;
  box-shadow: 2px 5px 25px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin: 2rem 0;
  padding: 2rem;
  text-decoration: none;

  h3 {
    font-size: 1.5rem;
  }

  p {
    /* color: #555; */
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    flex-basis: calc(50% - 4rem);
    margin: 2rem;
  }

  @media (min-width: 992px) {
    flex-basis: calc(33.333% - 4rem);
  }
`;

const PostHeader = styled('p')`
  color: #aaa;
  font-size: 0.75rem;
`;

const Lead = styled('p')`
  flex-grow: 1;
`;

const PostPreview = ({ post }) => (
  <Article>
    <h3>{post.title}</h3>

    <PostHeader>
      {post.date} • {post.timeToRead} min read
    </PostHeader>
    <Lead>{post.lead}</Lead>

    <div>
      <CallToAction to={`/blog/${post.slug}`}>
        Let's read it &rarr;
      </CallToAction>
    </div>
  </Article>
);

export default PostPreview;
