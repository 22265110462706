import styled from '@emotion/styled';

const Quote = styled('div')`
  padding-left: 2rem;

  p {
    color: rgba(0, 0, 0, 0.54) !important;
    font-size: 1.5rem !important;
    font-weight: lighter;
    margin-top: 1rem !important;
  }

  @media (min-width: 768px) {
    padding-left: 0;

    p {
      font-size: 2rem !important;
      text-align: center;
    }
  }
`;

export default Quote;
