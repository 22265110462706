import styled from '@emotion/styled';
import { Link } from 'gatsby';

const ReadLink = styled(Link)`
  display: inline-block;
  color: #222;
  font-size: 0.875rem;
  opacity: 0.5;
  text-decoration: none;
  transition: opacity 0.1s ease-out;

  &:hover {
    opacity: 1;
  }
`;

export default ReadLink;
