import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import styled from '@emotion/styled';

const SignatureImage = styled(Image)`
  margin-top: 3rem;
  max-width: 200px;
`;

const Signature = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "signature.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <SignatureImage
      fluid={image.sharp.fluid}
      alt="Norbert Kalácska portrait"
      fadeIn
    />
  );
};

export default Signature;
