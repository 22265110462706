import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import Title from './title';

const HeroSection = styled('section')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 4rem 0;
  max-width: 770px;
  min-height: 50vh;
  padding: 0;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const Hero = ({ children, title }) => {
  const lines = [
    {
      x1: 192,
      y1: 0,
      x2: 150,
      y2: 2000,
    },
    {
      x1: 280,
      y1: 0,
      x2: 320,
      y2: 2000,
    },
    {
      x1: 400,
      y1: 0,
      x2: 400,
      y2: 2000,
    },
    {
      x1: 500,
      y1: 0,
      x2: 440,
      y2: 2000,
    },
    {
      x1: 660,
      y1: 0,
      x2: 700,
      y2: 2000,
    },
    {
      x1: 890,
      y1: 0,
      x2: 800,
      y2: 2000,
    },
    {
      x1: 192 + 900,
      y1: 0,
      x2: 150 + 900,
      y2: 2000,
    },
    {
      x1: 280 + 900,
      y1: 0,
      x2: 320 + 900,
      y2: 2000,
    },
    {
      x1: 400 + 900,
      y1: 0,
      x2: 400 + 900,
      y2: 2000,
    },
    {
      x1: 500 + 900,
      y1: 0,
      x2: 440 + 900,
      y2: 2000,
    },
    {
      x1: 660 + 900,
      y1: 0,
      x2: 700 + 900,
      y2: 2000,
    },
    {
      x1: 890 + 900,
      y1: 0,
      x2: 800 + 900,
      y2: 2000,
    },
  ];

  return (
    <HeroSection>
      <svg
        viewBox="0 0 2000 2000"
        preserveAspectRatio="xMidYMid slice"
        css={css`
          height: 100vh;
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          z-index: -1;
        `}
      >
        <rect
          x="0"
          y="0"
          css={css`
            fill-opacity: 0;
            height: 100%;
            stroke: #ececec;
            stroke-width: 1px;
            width: 100%;
          `}
        />
        {lines.map((line, i) => (
          <line
            key={`line-${i}`}
            x1={line.x1}
            y1={line.y1}
            x2={line.x2}
            y2={line.y2}
            css={css`
              stroke: #ececec;
              stroke-width: 1px;
            `}
          />
        ))}
      </svg>
      {children ? children : <Title>{title}</Title>}
    </HeroSection>
  );
};

export default Hero;
